let on = {};

on.KeyDown = (e) => {
   if(e.key === 'Enter'){ e.preventDefault(); }
}

on.PasteOne = (e) => {
   e.preventDefault();
   navigator.clipboard.readText()
   .then(text => { text = text.replace(/^\s*/,'').replace(/\s*$/,'');  window.document.execCommand('insertText', false, text); })
   .catch(err => {  });
}

on.Focus = (e) => {
   if(e.target.textContent.length === 0){
    e.target.innerHTML = `<br>`;
   }
}

export default on;
