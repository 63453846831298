import React from 'react'
import Store from './Store';
import Notice from './component/Notice';
const Api = {};

Api.url = "https://api.checkpot.tw-team.com/admin";

//https://api.checkpot.tw-team.com/admin/v1/stocks/create

Api.getImage = (n) => {
    return "https://api.checkpot.tw-team.com/image/"+n+".png"
}

Api.fetchAuth = async (j) => {
    try {
        let res = await fetch(Api.url+'/v1/auth', {method: 'post', body: JSON.stringify(j), headers: { "Content-type": "application/json;charset=utf-8"}});
        res = await res.json();
        if(res.status === "error") Notice.Send({ 'type': "error", 'text': res.text })
        Store.setSSID(res.ssid);
        return res;
    } catch (e) {
        Notice.Send({ 'type': "error", 'text': "Ошибка с сервером" })
        return { 'status': 'error' };
    }
}

Api.fetchInit = async () => {
    try {
        let res = await fetch(Api.url+'/v1/init', {method: 'post', body: JSON.stringify({}), headers: { "Content-type": "application/json;charset=utf-8", 'ssid': Store.getSSID()}});
        res = await res.json();
        if(res.status === 'logaut') return Store.logaut();
        return res;
    } catch (e) {
        Notice.Send({ 'type': "error", 'text': "Ошибка с сервером" })
        return { 'status': 'error' };
    }
}
Api.fetchUserList = async (j) => {
   try {
      let res = await fetch(Api.url+'/v1/user/list', {method: 'post', body: JSON.stringify({}), headers: { "Content-type": "application/json;charset=utf-8", 'ssid': Store.getSSID()  }});
      res = await res.json();
      if(res.status === 'logaut') return Store.logaut();
      return res;
   } catch (e) {
       Notice.Send({ 'type': "error", 'text': "Ошибка с сервером" })
       return [];
   }
}
Api.fetchUserAdd = async (j) => {
    try {
       let res = await fetch(Api.url+'/v1/user/add', {method: 'post', body: JSON.stringify(j), headers: { "Content-type": "application/json;charset=utf-8", 'ssid': Store.getSSID()  }});
       res = await res.json();
       return res;
     } catch (e) {
         Notice.Send({ 'type': "error", 'text': "Ошибка с сервером" })
         return { status: 'error' };
     }
}
Api.fetchUserEdit = async (j) => {
    try {
        let res = await fetch(Api.url+'/v1/user/edit', {method: 'post', body: JSON.stringify(j), headers: { "Content-type": "application/json;charset=utf-8", 'ssid': Store.getSSID()  }});
        res = await res.json();
        Notice.Send(res);
        return res;
    } catch (e) {
        Notice.Send({ 'type': "error", 'text': "Ошибка с сервером" })
        return { status: 'error' };
    }
}

Api.fetchOrderList = async (j) => {
   try {
      let res = await fetch(Api.url+'/v1/order/list', {method: 'post', body: JSON.stringify({}), headers: { "Content-type": "application/json;charset=utf-8", 'ssid': Store.getSSID()  }});
      res = await res.json();
      if(res.status === 'logaut') return Store.logaut();
      return res;
   } catch (e) {
       Notice.Send({ 'type': "error", 'text': "Ошибка с сервером" })
       return [];
   }
}
Api.fetchOrderEdit = async (j) => {
   try {
      let res = await fetch(Api.url+'/v1/order/edit', {method: 'post', body: JSON.stringify(j), headers: { "Content-type": "application/json;charset=utf-8", 'ssid': Store.getSSID()  }});
      res = await res.json();
      if(res.status === 'logaut') return Store.logaut();
      return res;
   } catch (e) {
       Notice.Send({ 'type': "error", 'text': "Ошибка с сервером" })
       return [];
   }
}


Api.fetchUploadImage = async (f) => {
   try {
      let res = await fetch(Api.url+'/v1/upload', {method: 'POST', mode: 'cors', body: f, headers: { 'ssid': Store.getSSID() }});
      res = await res.json();
      if(res.status === 'logaut') return Store.logaut();
      return res;
   } catch (e) {
       Notice.Send({ 'type': "error", 'text': "Ошибка с сервером" })
       return [];
   }
}

Api.fetchUploadImageVk = async (f) => {
   try {
      let res = await fetch(Api.url+'/v1/widgets/upload', {method: 'POST', body: f, mode: 'cors', headers: { 'ssid': Store.getSSID()  }});
      res = await res.json();
      if(res.status === 'logaut') return Store.logaut();
      return res;
   } catch (e) {
       Notice.Send({ 'type': "error", 'text': "Ошибка с сервером" })
       return [];
   }
}

Api.fetchBannerUpload = async (f) => {
   try {
      let res = await fetch(Api.url+'/v1/banner/upload', {method: 'POST', body: f, mode: 'cors', headers: { 'ssid': Store.getSSID()  }});
      res = await res.json();
      if(res.status === 'logaut') return Store.logaut();
      return res;
   } catch (e) {
       Notice.Send({ 'type': "error", 'text': "Ошибка с сервером" })
       return [];
   }
}

Api.fetchWidgetsUpdate = async (j) => {
   try {
      let res = await fetch(Api.url+'/v1/widgets/update', {method: 'POST', body: JSON.stringify(j), headers: { "Content-type": "application/json;charset=utf-8",'ssid': Store.getSSID()  }});
      res = await res.json();
      if(res.status === 'logaut') return Store.logaut();
      return res;
   } catch (e) {
       Notice.Send({ 'type': "error", 'text': "Ошибка с сервером" })
       return {};
   }
}

Api.fetchWidgetsText = async (j) => {
   try {
      let res = await fetch(Api.url+'/v1/widgets/text', {method: 'POST', body: JSON.stringify(j), headers: { "Content-type": "application/json;charset=utf-8",'ssid': Store.getSSID()  }});
      res = await res.json();
      if(res.status === 'logaut') return Store.logaut();
      return res;
   } catch (e) {
       Notice.Send({ 'type': "error", 'text': "Ошибка с сервером" })
       return {};
   }
}


Api.fetchWidgetsStatus = async (j) => {
   try {
      let res = await fetch(Api.url+'/v1/widgets/status', {method: 'POST', body: JSON.stringify(j), headers: { "Content-type": "application/json;charset=utf-8",'ssid': Store.getSSID()  }});
      res = await res.json();
      if(res.status === 'logaut') return Store.logaut();
      return res;
   } catch (e) {
       Notice.Send({ 'type': "error", 'text': "Ошибка с сервером" })
       return {};
   }
}

Api.fetchBannerUpdate = async (j) => {
   try {
       let res = await fetch(Api.url+'/v1/banner/update', {
         method: 'POST',
         body: JSON.stringify(j),
         headers: { "Content-type": "application/json;charset=utf-8",'ssid': Store.getSSID()  }
       });
       res = await res.json();
       if(res.status === 'logaut') return Store.logaut();
       return res;
   } catch (e) {
       Notice.Send({ 'type': "error", 'text': "Ошибка с сервером" })
       return {};
   }
}

Api.fetchStocksList = async (j) => {
   try {
      let res = await fetch(Api.url+'/v1/stocks/list', {method: 'post', body: JSON.stringify({}), headers: { "Content-type": "application/json;charset=utf-8", 'ssid': Store.getSSID()  }});
      res = await res.json();
      if(res.status === 'logaut') return Store.logaut();
      return res;
   } catch (e) {
       Notice.Send({ 'type': "error", 'text': "Ошибка с сервером" })
       return [];
   }
}
Api.fetchStocksStatus = async (j) => {
   try {
      let res = await fetch(Api.url+'/v1/stocks/status', {method: 'post', body: JSON.stringify(j), headers: { "Content-type": "application/json;charset=utf-8", 'ssid': Store.getSSID()  }});
      res = await res.json();
      if(res.status === 'logaut') return Store.logaut();
      return res;
   } catch (e) {
       Notice.Send({ 'type': "error", 'text': "Ошибка с сервером" })
       return [];
   }
}
Api.fetchStocksTest = async (j) => {
   try {
      let res = await fetch(Api.url+'/v1/stocks/test', {method: 'post', body: JSON.stringify(j), headers: { "Content-type": "application/json;charset=utf-8", 'ssid': Store.getSSID()  }});
      res = await res.json();
      if(res.status === 'logaut') return Store.logaut();
      Notice.Send(res)
      return res;
   } catch (e) {
       Notice.Send({ 'type': "error", 'text': "Ошибка с сервером" })
       return [];
   }
}
Api.fetchStocksData = async (j) => {
   try {
      let res = await fetch(Api.url+'/v1/stocks/data', {method: 'post', body: JSON.stringify(j), headers: { "Content-type": "application/json;charset=utf-8", 'ssid': Store.getSSID()  }});
      res = await res.json();
      if(res.status === 'logaut') return Store.logaut();
      return res;
   } catch (e) {
       Notice.Send({ 'type': "error", 'text': "Ошибка с сервером" })
       return [];
   }
}
Api.fetchStocksDelete = async (j) => {
   try {
      let res = await fetch(Api.url+'/v1/stocks/delete', {method: 'post', body: JSON.stringify(j), headers: { "Content-type": "application/json;charset=utf-8", 'ssid': Store.getSSID()  }});
      res = await res.json();
      if(res.status === 'logaut') return Store.logaut();
      return res;
   } catch (e) {
       Notice.Send({ 'type': "error", 'text': "Ошибка с сервером" })
       return [];
   }
}
Api.fetchStocksCreate = async (j) => {
   try {
      let res = await fetch(Api.url+'/v1/stocks/create', {method: 'post', body: JSON.stringify(j), headers: { "Content-type": "application/json;charset=utf-8", 'ssid': Store.getSSID()  }});
      res = await res.json();
      if(res.status === 'logaut') return Store.logaut();
      return res;
   } catch (e) {
       Notice.Send({ 'type': "error", 'text': "Ошибка с сервером" })
       return [];
   }
}
Api.fetchStocksEdit = async (j) => {
   try {
      let res = await fetch(Api.url+'/v1/stocks/edit', {method: 'post', body: JSON.stringify(j), headers: { "Content-type": "application/json;charset=utf-8", 'ssid': Store.getSSID()  }});
      res = await res.json();
      if(res.status === 'logaut') return Store.logaut();
      return res;
   } catch (e) {
       Notice.Send({ 'type': "error", 'text': "Ошибка с сервером" })
       return [];
   }
}
Api.fetchStocksIcon = async (j) => {
   try {
      let res = await fetch(Api.url+'/v1/stocks/icon', {method: 'post', body: JSON.stringify(j), headers: { "Content-type": "application/json;charset=utf-8", 'ssid': Store.getSSID()  }});
      res = await res.json();
      if(res.status === 'logaut') return Store.logaut();
      return res;
   } catch (e) {
       Notice.Send({ 'type': "error", 'text': "Ошибка с сервером" })
       return [];
   }
}

Api.fetchChequesList = async (j) => {
   try {
      let res = await fetch(Api.url+'/v1/cheques/list', {method: 'post', body: JSON.stringify(j), headers: { "Content-type": "application/json;charset=utf-8", 'ssid': Store.getSSID()  }});
      res = await res.json();
      if(res.status === 'logaut') return Store.logaut();
      return res;
   } catch (e) {
       Notice.Send({ 'type': "error", 'text': "Ошибка с сервером" })
       return [];
   }
}
Api.fetchChequesReload = async (j) => {
   try {
      let res = await fetch(Api.url+'/v1/cheques/reload', {method: 'post', body: JSON.stringify(j), headers: { "Content-type": "application/json;charset=utf-8", 'ssid': Store.getSSID()  }});
      res = await res.json();
      if(res.status === 'logaut') return Store.logaut();
      return res;
   } catch (e) {
       Notice.Send({ 'type': "error", 'text': "Ошибка с сервером" })
       return [];
   }
}
Api.fetchChequesStatus = async (j) => {
   try {
      let res = await fetch(Api.url+'/v1/cheques/status', {method: 'post', body: JSON.stringify(j), headers: { "Content-type": "application/json;charset=utf-8", 'ssid': Store.getSSID()  }});
      res = await res.json();
      if(res.status === 'logaut') return Store.logaut();
      return res;
   } catch (e) {
       Notice.Send({ 'type': "error", 'text': "Ошибка с сервером" })
       return [];
   }
}
Api.fetchChequesTicket = async (j) => {
   try {
      let res = await fetch(Api.url+'/v1/cheques/ticket', {method: 'post', body: JSON.stringify(j), headers: { "Content-type": "application/json;charset=utf-8", 'ssid': Store.getSSID()  }});
      res = await res.json();
      if(res.status === 'logaut') return Store.logaut();
      return res;
   } catch (e) {
       Notice.Send({ 'type': "error", 'text': "Ошибка с сервером" })
       return [];
   }
}

Api.fetchQuesList = async (j) => {
   try {
      let res = await fetch(Api.url+'/v1/ques/list', {method: 'post', body: JSON.stringify({}), headers: { "Content-type": "application/json;charset=utf-8", 'ssid': Store.getSSID()  }});
      res = await res.json();
      if(res.status === 'logaut') return Store.logaut();
      return res;
   } catch (e) {
       Notice.Send({ 'type': "error", 'text': "Ошибка с сервером" })
       return [];
   }
}
Api.fetchQuesData = async (j) => {
   try {
      let res = await fetch(Api.url+'/v1/ques/data', {method: 'post', body: JSON.stringify({}), headers: { "Content-type": "application/json;charset=utf-8", 'ssid': Store.getSSID()  }});
      res = await res.json();
      if(res.status === 'logaut') return Store.logaut();
      return res;
   } catch (e) {
       Notice.Send({ 'type': "error", 'text': "Ошибка с сервером" })
       return [];
   }
}

Api.fetchCompanyList = async (j) => {
   try {
      let res = await fetch(Api.url+'/v1/company/list', {method: 'post', body: JSON.stringify({}), headers: { "Content-type": "application/json;charset=utf-8", 'ssid': Store.getSSID()  }});
      res = await res.json();
      if(res.status === 'logaut') return Store.logaut();
      return res;
   } catch (e) {
       Notice.Send({ 'type': "error", 'text': "Ошибка с сервером" })
       return [];
   }
}
Api.fetchCompanyEdit = async (j) => {
   try {
      let res = await fetch(Api.url+'/v1/company/edit', {method: 'post', body: JSON.stringify(j), headers: { "Content-type": "application/json;charset=utf-8", 'ssid': Store.getSSID()  }});
      res = await res.json();
      if(res.status === 'logaut') return Store.logaut();
      Notice.Send(res)
      return res;
   } catch (e) {
       Notice.Send({ 'type': "error", 'text': "Ошибка с сервером" })
       return [];
   }
}

Api.fetchTelegram = async (j) => {
    try {
        let res = await fetch('https://sp.elcrm.online/v1/telegram', {method: 'post', body: JSON.stringify(j),
         headers: { "Content-type": "application/json;charset=utf-8", 'ssid': Store.getSSID()  }});
        res = await res.json();
        if(res.status === 'logaut') return Store.logaut();
        return res;
    } catch (e) {
        Notice.Send({ 'type': "error", 'text': "Ошибка с сервером" })
        return { status: 'error' };
    }
}

Api.fetchStaff = async (j) => {
    try {
        let res = await fetch('https://sp.elcrm.online/v1/staff', {method: 'post', body: JSON.stringify(j), headers: { "Content-type": "application/json;charset=utf-8", 'ssid': Store.getSSID()  }});
        res = await res.json();
        if(res.status === 'logaut') return Store.logaut();
        return res;
    } catch (e) {
        Notice.Send({ 'type': "error", 'text': "Ошибка с сервером" })
        return { status: 'error' };
    }
}

Api.fetchParse = async (id) => {
    try {
        let res = await fetch('https://www.wildberries.ru/webapi/seller/data/short/688123');
        res = await res.json();
        return res;
    } catch (e) {
       console.log(e);
    }
}

Api.fetchProductParse = async (j) => {
    try {
        let res = await fetch('https://'+Store.getSITE()+'/upload/base', {method: 'post', body: JSON.stringify(j), headers: { "Content-type": "application/json;charset=utf-8", 'ssid': Store.getSSID()  }});
        res = await res.json();
        return res;
    } catch (e) {
        Notice.Send({ 'type': "error", 'text': "Ошибка с сервером" })
        return { status: 'error' };
    }
}
Api.fetchProductList = async (j) => {
    try {
        let res = await fetch('https://'+Store.getSITE()+'/products/list', {method: 'post', body: JSON.stringify(j), headers: { "Content-type": "application/json;charset=utf-8", 'ssid': Store.getSSID()  }});
        res = await res.json();

        if(res.status === "logout"){
            Store.logaut();
            return [];
        }

        if(res.status === "error"){
            Notice.Send({ 'type': "error", 'text': "Ошибка с сервером" })
            return [];
        }

        return res.data;

    } catch (e) {
        Notice.Send({ 'type': "error", 'text': "Ошибка с сервером" })
        return [];
    }
}
Api.fetchProductEdit = async (j) => {
    try {
        let res = await fetch('https://'+Store.getSITE()+'/products/edit', {method: 'post', body: JSON.stringify(j), headers: { "Content-type": "application/json;charset=utf-8", 'ssid': Store.getSSID()  }});
        res = await res.json();
        Notice.Send({ 'type': res.status, 'text': res.text });
        return res;
    } catch (e) {
        Notice.Send({ 'type': "error", 'text': "Ошибка с сервером" })
        return { status: 'error' };
    }
}
Api.fetchProductTitle = async (j) => {
    try {
        let res = await fetch('https://'+Store.getSITE()+'/products/title', {method: 'post', body: JSON.stringify(j), headers: { "Content-type": "application/json;charset=utf-8", 'ssid': Store.getSSID()  }});
        res = await res.json();
        Notice.Send({ 'type': res.status, 'text': res.text });
        return res;
    } catch (e) {
        Notice.Send({ 'type': "error", 'text': "Ошибка с сервером" })
        return { status: 'error' };
    }
}

Api.fetchProductInfo = async (j) => {
    try {
        let res = await fetch('https://'+Store.getSITE()+'/products/info', {method: 'post', body: JSON.stringify({ id: j}), headers: { "Content-type": "application/json;charset=utf-8", 'ssid': Store.getSSID()  }});
        res = await res.json();
        return res;
    } catch (e) {
        Notice.Send({ 'type': "error", 'text': "Ошибка с сервером" })
        return { status: 'error' };
    }
}
Api.fetchProductAdd = async (j) => {
    try {
        let res = await fetch('https://'+Store.getSITE()+'/products/add', {method: 'post', body: JSON.stringify({}), headers: { "Content-type": "application/json;charset=utf-8", 'ssid': Store.getSSID()  }});
        res = await res.json();
        return res;
    } catch (e) {
        Notice.Send({ 'type': "error", 'text': "Ошибка с сервером" })
        return { status: 'error' };
    }
}
Api.fetchProductLinks = async () => {
    try {
        let res = await fetch('https://'+Store.getSITE()+'/products/links', {method: 'post', body: JSON.stringify({}), headers: { "Content-type": "application/json;charset=utf-8", 'ssid': Store.getSSID()  }});
        res = await res.json();
        return res;
    } catch (e) {
        Notice.Send({ 'type': "error", 'text': "Ошибка с сервером" })
        return { status: 'error' };
    }
}

Api.fetchCategoriesList = async (j) => {
    try {
        let res = await fetch('https://'+Store.getSITE()+'/categories/list', {method: 'post', body: JSON.stringify(j), headers: { "Content-type": "application/json;charset=utf-8", 'ssid': Store.getSSID()  }});
        res = await res.json();
        return res;
    } catch (e) {
        Notice.Send({ 'type': "error", 'text': "Ошибка с сервером" })
        return { status: 'error' };
    }
}
Api.fetchCategoriesAdd = async () => {
    try {
        let res = await fetch('https://'+Store.getSITE()+'/categories/add', {method: 'post', body: JSON.stringify({}), headers: { "Content-type": "application/json;charset=utf-8", 'ssid': Store.getSSID()  }});
        res = await res.json();
        return res;
    } catch (e) {
        Notice.Send({ 'type': "error", 'text': "Ошибка с сервером" })
        return { status: 'error' };
    }
}
Api.fetchCategoriesEdit = async (j) => {
    try {
        let res = await fetch('https://'+Store.getSITE()+'/categories/edit', {method: 'post', body: JSON.stringify(j), headers: { "Content-type": "application/json;charset=utf-8", 'ssid': Store.getSSID()  }});
        res = await res.json();
        return res;
    } catch (e) {
        Notice.Send({ 'type': "error", 'text': "Ошибка с сервером" })
        return { status: 'error' };
    }
}
Api.fetchCategoriesInfo = async (j) => {
    try {
        let res = await fetch('https://'+Store.getSITE()+'/categories/info', {method: 'post', body: JSON.stringify({ id: j}), headers: { "Content-type": "application/json;charset=utf-8", 'ssid': Store.getSSID()  }});
        res = await res.json();
        return res;
    } catch (e) {
        Notice.Send({ 'type': "error", 'text': "Ошибка с сервером" })
        return { status: 'error' };
    }
}


Api.fetchCommentList = async (j) => {
  try {
      let res = await fetch('https://'+Store.getSITE()+'/comment/list', {method: 'post', body: JSON.stringify(j), headers: { "Content-type": "application/json;charset=utf-8", 'ssid': Store.getSSID()  }});
      res = await res.json();
      return res;
  } catch (e) {
      Notice.Send({ 'type': "error", 'text': "Ошибка с сервером" })
      return { status: 'error' };
  }
}
Api.fetchCommentEdit = async (j) => {
    try {
        let res = await fetch('https://'+Store.getSITE()+'/comment/edit', {method: 'post', body: JSON.stringify(j), headers: { "Content-type": "application/json;charset=utf-8", 'ssid': Store.getSSID()  }});
        res = await res.json();
        Notice.Send({ 'type': res.status, 'text': res.text });
        return res;
    } catch (e) {
        Notice.Send({ 'type': "error", 'text': "Ошибка с сервером" })
        return { status: 'error' };
    }
}
Api.fetchCommentInfo = async (j) => {
    try {
        let res = await fetch('https://'+Store.getSITE()+'/comment/info', {method: 'post', body: JSON.stringify({ id: j}), headers: { "Content-type": "application/json;charset=utf-8", 'ssid': Store.getSSID()  }});
        res = await res.json();
        return res;
    } catch (e) {
        Notice.Send({ 'type': "error", 'text': "Ошибка с сервером" })
        return { status: 'error' };
    }
}
Api.fetchCommentDelete = async (j) => {
    try {
        let res = await fetch('https://'+Store.getSITE()+'/comment/delete', {method: 'post', body: JSON.stringify({ id: j}), headers: { "Content-type": "application/json;charset=utf-8", 'ssid': Store.getSSID()  }});
        res = await res.json();
        Notice.Send({ 'type': res.status, 'text': res.text })
        return res;
    } catch (e) {
        Notice.Send({ 'type': "error", 'text': "Ошибка с сервером" })
        return { status: 'error' };
    }
}

Api.fetchInfoList = async (j) => {
  try {
      let res = await fetch('https://'+Store.getSITE()+'/info/list', {method: 'post', body: JSON.stringify(j), headers: { "Content-type": "application/json;charset=utf-8", 'ssid': Store.getSSID()  }});
      res = await res.json();
      return res;
  } catch (e) {
      Notice.Send({ 'type': "error", 'text': "Ошибка с сервером" })
      return { status: 'error' };
  }
}
Api.fetchInfoEdit = async (j) => {
    try {
        let res = await fetch('https://'+Store.getSITE()+'/info/edit', {method: 'post', body: JSON.stringify(j), headers: { "Content-type": "application/json;charset=utf-8", 'ssid': Store.getSSID()  }});
        res = await res.json();
          Notice.Send({ 'type': res.status, 'text': res.text });
        return res;
    } catch (e) {
        Notice.Send({ 'type': "error", 'text': "Ошибка с сервером" })
        return { status: 'error' };
    }
}

Api.fetchMainLoad = async (j) => {
    let res = await fetch('https://'+Store.getSITE()+'/main/load', {method: 'post', body: JSON.stringify(j), headers: { "Content-type": "application/json;charset=utf-8", 'ssid': Store.getSSID()  }});
    res = await res.json();
    return res;
}

export default Api;
