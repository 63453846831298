import React from 'react';
import on from './.onEvent';

const Password = ({label,error,view,name,value,onValue,after,onBlur,placeholder}) => {

    const [secret, setSecret] = React.useState('true');

    const onInput = (e) => {
         if(onValue === undefined) return;
         onValue({'value' : e.target.textContent, 'name': name});
    }

    let link = () => {
       setSecret((secret === 'true') ? 'false' : 'true');
    }

    let _onBlur = (e) => {
        if(e.target.textContent.length === 0){ e.target.textContent = ""; }
        if(onBlur !== undefined) onBlur(e);
    }

    return (
      <div ui-field="password" secret={secret} >
          {(label) ? <dt>{label}</dt> : ""}
          <div className="--input"
               name={name}
               onInput={onInput}
               onPaste={on.PasteOne}
               onKeyDown={on.KeyDown}
               onBlur={_onBlur}
               onFocus={on.Focus}
               placeholder={placeholder}
               contentEditable
               suppressContentEditableWarning="true"
               after={after} >{value}</div>
          <i className={`icon icon-io-eye-${(secret === "true") ? 'filled' : 'off-filled'}`} onClick={() => link()} />
          {(error) ? <em>{error}</em> : ""}
      </div>
    );
}

export default Password;
