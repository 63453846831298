import React, { Suspense  } from 'react';
import Store from './Store';

import Form from './component/Form';
import Modal from './component/Modal';
import Notice from './component/Notice';
import Alerts from './component/Alerts';
import Api from './Api';

import './css/App.css';
import './css/Form.css';
import './css/Select.css';
import './fonts/iconfont.css';

Store.load();
Store.BasaLoad();

const App = () => {

  let page = Store.usePage()

  const Pages = React.lazy(() => import(`./pages/${page}`));

  return (<>
           reerewrewr
          </>)
}

export default App;
