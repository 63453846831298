export default function(value){
  let text = "";
  for(let member in value) {
     if(member === "8"){
        text += " "
     }else{
        let s = ['4','6'].includes(member) ? "." : "";
            s = ['11'].includes(member) ? ":" : s;
        text += `${s}${value[member]}`;
     }
  }
  return text;
}
