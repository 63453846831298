import React from 'react';
import Formatting from '../Formatting';
import Form from '../Form';

const _Date = (props) => {

  let title = () => {  return (props.title) ? <dt>{props.title}</dt> : '';  }
  let error = () => {  return (props.error) ? <em>{props.error}</em> : '';  }
  let edit = (props.edit !== undefined) ? props.edit : true;
  let active = (props.active !== undefined) ? props.active : true;
  let placeholder = (props.placeholder !== undefined) ? props.placeholder : '';
  let [date,setDate] = React.useState((props.value === undefined) ? new Date() : props.value);
  let hide = (!edit && active);

  React.useEffect(() => {
      setDate((props.value === undefined) ? new Date() : props.value);
  },[props.value]);

  if(edit){   }

  if(active){   }

  let send = (d) => { setDate(d); props.onValue({value : d, name: props.name}); }

  let open = (e) => {
      const element = e.target.getBoundingClientRect();
      const x = element.left;
      const y = element.top;
      Form.open(<BoxDate key={new Date().getTime()} position={{x,y}} onValue={send}  date={date} />);
  }

  if(hide){
     return(<></>)
  }else{

  return (< >{(props.view === 'icon') ? <i data-icon="F043" className="pointer" data-color="_b5b9cc :506290" onClick={open} ></i> :
  <dl type='date' view={props.view}>
  {title()}
  <dd onClick={open}
      placeholder={placeholder}
      data-edit={(edit && active)+''}
      after={props.after} >
      {Formatting.Date(date,'dd.mm.yyyy')}
  </dd>
  {error()}
  </dl>
   }</>)
  }

}

export default _Date;

const BoxDate = (props) => {
  let [active,setActive] = React.useState(true);

  let _current = new Date();
  let _selecte = new Date(props.date);

  let current = {y: parseInt(_current.getFullYear()), d: parseInt(_current.getDate()), m: parseInt(_current.getMonth()) + 1};
  let selecte = {y: parseInt(_selecte.getFullYear()), d: parseInt(_selecte.getDate()), m: parseInt(_selecte.getMonth()) + 1};
  let [month,setMonth] = React.useState({y:selecte.y,m:selecte.m});

   let g = (e) => {let da = e.getDay(); if (da === 0) da = 7; return da - 1;}

   let week = ["пн", "вт", "ср", "чт", "пт", "сб", "вс"];

   let o = month.m - 1;
   let d = new Date(month.y, o);
   let days = [];

   for (let i = 0; i < g(d); i++){ days.push(0); }
   while(d.getMonth() === o){ days.push(d.getDate());  d.setDate(d.getDate() + 1); }

   let exit = () => { setActive(false);  }
   let next = () => { update(month.m + 1,month.y) }
   let prev = () => { update(month.m - 1,month.y) }
   let send = (d) => { props.onValue(month.y+'-'+Formatting.Number(2,month.m)+'-'+Formatting.Number(2,d)); exit();   }
   let update = (m,y) => { y = (m > 12) ? (y + 1) : (m < 1) ? (y - 1) : y; m = (m > 12) ? 1 : (m < 1) ? 12 : m; setMonth({y,m}); }

   let y = ((window.innerHeight - props.position.y + 33) < 320) ? props.position.y - 312 : props.position.y + 33;
   let x = ((window.innerWidth - props.position.x) < 265) ? props.position.x - 235 : props.position.x;

   return (<>
      {(active) ?  <><select-o key={new Date().getTime()} onClick={() => exit()} ></select-o>
                 <select-c style={{top: y + 'px', left: x+"px" }}   >
                 <s><i data-icon="F021" onClick={() => prev()}></i><h2 >{Formatting.Month(month.m).name} {month.y}</h2><i data-icon="F022" onClick={() => next()}></i></s>
                 <div>
                 {week.map((i,s) => (<p key={'v'+s} >{i}</p>))}
                 {days.map((i,s) => (<span key={s}>{(i !== 0) ? <b color={(current.y === month.y && current.m === month.m && current.d === i) ? '_FFEB3B' : '' } onClick={() => send(i)} >{i}</b> : <p></p>}</span>))}
                 </div>
                 </select-c></>
                 : '' }</>)
   }
