import React from 'react';
import on from './.onEvent';

const Search = React.memo(({label,error,view,name,value,thema,onValue,onSave,after,onBlur,item,placeholder,contextmenu,iSedit = true}) => {

  const text = React.useRef(value);

  let handleChange = (e) => {
      text.current = e.target.textContent;
      if(onValue === undefined) return;
      onValue({value : e.target.textContent});
  };

  return (
        <dl ui-field="search" className={thema} >
           <i className='icon icon-io-search-outline' />
           <div onInput={handleChange}
                onPaste={on.PasteOne}
                placeholder={placeholder}
                contentEditable="true"
                suppressContentEditableWarning="true"
                >

          </div>
        </dl>
  );
});

export default Search;
