import React from 'react';

const Button = ({label,onClick,onRef,style}) => {

  let [disable,setDisable] = React.useState(false);

  const _onClick = () => {
     if(disable) return;
     setDisable(true);
     onClick(setDisable)
  }

  return (
     <div ui-field="button" >
        <button ref={onRef} style={style} className={!disable ?  "btn confirm pointer" : "btn await" }  onClick={_onClick}>{!disable ? label : "Ожидайте"}</button>
     </div>
  );
}

export default Button;
