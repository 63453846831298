import React from 'react';
import Formatting from '../Formatting';

const Price = React.memo(({label,error,onValue,onBlur,value,max,type,name,view,contextmenu,onSave,id,placeholder}) => {

  let [edit,setEdit] = React.useState((contextmenu === undefined) ? true : false);
  const text = React.useRef(value);

  let contextMenu = (e) => {
      if(contextmenu !== true) return;
      e.preventDefault();
      e.target.setAttribute('contentEditable','true')
      e.target.focus();
      setEdit(!edit);
  }

  let replace = (s) => {
     if(type === 'multi'){
        return s.replace(/[^+\d.+-]/g,'');
     }else{
        return s.replace(/[^+\d.]/g,'');
     }
  }

  let setCursorPosition = (pos, elem) => {
      let setpos = document.createRange();
      let set = window.getSelection();
      setpos.setStart(elem.childNodes[0], pos);
      setpos.collapse(true);
      set.removeAllRanges();
      set.addRange(setpos);
      elem.focus();
  }

  let input = (e) => {
     text.current = e.target.textContent;
     if(onValue !== undefined) onValue({value : replace(e.target.textContent), name: name, 'id': id});
     e.target.textContent = replace(e.target.textContent);
     if(e.target.textContent.length > 0){
          setCursorPosition(e.target.textContent.length,e.target);
     }
  };

  let blur = (e) => {
      if(e.target.textContent === '') e.target.textContent = 0;
      if(onBlur !== undefined) onBlur({value : replace(e.target.textContent), name: name, 'id': id});
      e.target.textContent = Formatting.Money(e.target.textContent);
  };

  let focus = (e) => {
      e.target.textContent = replace(e.target.textContent);
  };

  let keyDown = (e) => {
      if(e.key === 'Enter'){ e.preventDefault(); }

  }

  let keyUp = (e) => {
      if(e.key === 'Enter'){ e.preventDefault(); }
  }

  let paste = (e) => {
      e.preventDefault();
      navigator.clipboard.readText()
      .then(text => { text = text.replace(/^\s*/,'').replace(/\s*$/,''); window.document.execCommand('insertText', false, text); })
      .catch(err => { });
  }

  return (<dl ui-field="summa" >
              {(label) ? <dt>{label}</dt> : ""}
              <dd onInput={input} edit={''+edit} className="--input" onContextMenu={contextMenu} onPaste={paste} onBlur={blur} onKeyDown={keyDown} onKeyUp={keyUp} onFocus={focus} contentEditable={''+edit} placeholder={placeholder}  suppressContentEditableWarning="true" >{Formatting.Money(value)}</dd>
              {(contextmenu && edit) ? <div className='form_backgraund' onClick={() => { onSave({ value: text.current, name: name, id: id  }); setEdit(false)} } ></div> : '' }
          </dl>);
});

export default Price;
