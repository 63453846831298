import React from 'react';
import Store from '../../Store';

const Categories = ({value,onValue,name,id,label,placeholder,edit}) => {

  const [data, setData] = React.useState({ 'value': '' });
  const [options, setOptions] = React.useState([]);

  React.useEffect(() => {
     let o = Store.getBase('categories');
     let u = [];
     u.push({ id: 0, title: "нет" });
     Object.keys(o.data).forEach(i => {
       u.push({ id: i, title: o.data[i].title });
     });
     let a = u.find(i => parseInt(i.id) === parseInt(value))?.title;
      setOptions(u);
     setData({ value: a });
  },[])

  let link = (_id) => {

      if(onValue !== undefined) onValue({ value: _id, name: "category", id: id });
      setData({ value: options.find(i => parseInt(i.id) === parseInt(_id)).title})
  }

  let select = (e) => {
     if(edit === false) return;
     e.preventDefault();
     let dd = e.target.getBoundingClientRect();
     let div = document.createElement("div");
     div.classList.add("field-select-menu");
     div.style.width = dd.width+'px';
     div.style.top = (dd.top+dd.height)+'px';
     div.style.left = dd.left+'px';
     options.forEach(i => {
       let l = document.createElement("label");
       l.textContent = i.title;
       l.addEventListener('click',() => {
          link(i.id);
          div.remove()
          document.removeEventListener('mousedown', onMouseDown);
       }, false);
       div.appendChild(l);
    });

    document.body.appendChild(div);

    function onMouseDown(e){
       e.preventDefault();
       if(e.path.indexOf(div) === -1){
          div.remove()
          document.removeEventListener('mousedown', onMouseDown);
     }
     }
     document.addEventListener('mousedown', onMouseDown, false);
  }

  let title = () => {  return (label) ? <dt>{label}</dt> : '';  }

  return (
    <dl ui-field="select" >
        {title()}
        <dd className="--input" onClick={select} placeholder={placeholder}  >{data.value}</dd>
    </dl>
  );
}

export default Categories;
