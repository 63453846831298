'use strict'
import React from 'react';
import CryptoJS from "crypto-js";

const Store = {};
const _data = {};
const _listen = {};

const _base = ['brands','categories','mark','fields'];

const enCode = (q) => {
     return CryptoJS.AES.encrypt(JSON.stringify(q), 'qwertyu').toString();
}
const deCode = (q) => {
    let bytes = CryptoJS.AES.decrypt(q, 'qwertyu');
    try {
        CryptoJS.enc.Utf8.stringify(bytes);
    } catch (e){
        return false;
    }
    let str = CryptoJS.enc.Utf8.stringify(bytes);
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return JSON.parse(str);
}
const upload = (n) => {
   let e = localStorage.getItem(n);
   if(e !== '' && e !== 'undefined' && e !== null){
     try {
         let j = JSON.parse(e);
         _data[n] = { is: true,  time: j.time,  data: j.data }
     } catch (e) {
         _data[n] = { is: false, time: 0, data: {}}
     }
   }else{
         _data[n] = { is: false, time: 0, data: {}}
   }
}

Store.site = null;
Store.load = () => {
  let e = localStorage.getItem('page');
  if(e !== '' && e !== 'undefined' && e !== null){
     let a = deCode(localStorage.getItem('page'));
     if(!a) Store.logaut();
     _data.id = a.id;
     _data.tab = a.tab;
     _data.page = a.page;
     _data.module = a.module;
     _data.time = a.time;
     _data.sort = a.sort;
     _data.view = a.view;
     //['costumes','performers'].forEach(i => upload(i));
  }else{
     ['ssid','menu','page','brands','site','fields','mark','categories'].forEach(i => localStorage.removeItem(i));
     _data.id = 0;
     _data.tab = '';
     _data.page = 'Auth';
     _data.module = 'Main';
  }
}

Store.BasaLoad = () => {
   _base.forEach(i => upload(i));
}
Store.getBase = (n,i) => {
   if(i !== undefined){
      return (_data[n].data[i] !== undefined) ? _data[n].data[i].title : 'Нет';
   }else{
      return _data[n];
   }
}

Store.setBase = (n,j) => {
    _data[n] = { data: j.data, time: j.time};
    localStorage.setItem(n,JSON.stringify(_data[n]));
}
Store.logaut = () => {
   ['ssid','menu','page','brands','site','fields','mark','categories'].forEach(i => localStorage.removeItem(i));
   _base.forEach(i => localStorage.removeItem(i));
   window.location.reload(true);
}

Store.setSSID = (n) => {
    localStorage.setItem('ssid',n);
}
Store.getSSID = (n) => {
    return localStorage.getItem('ssid');
}

Store.setMenu = (n) => {
    Store.menu = n;
    localStorage.setItem('menu',enCode(n));
}
Store.getMenu = () => {
    if(Store.menu !== undefined) return Store.menu
    let s = localStorage.getItem('menu');
    s = deCode(s);
    if(!s) Store.logaut();
    Store.menu = s;
    if(Store.menu === null) return Store.logaut();
    return Store.menu;
}

Store.setSITE = (n) => {
    Store.site = n;
    localStorage.setItem('site',n);
}
Store.getSITE = (n) => {
    if(Store.site !== null) return Store.site
    Store.site = localStorage.getItem('site');
    if(Store.site === null) return Store.logaut();
    return Store.site;
}

Store.usePage = () => {
  const [page,setPage] = React.useState((_data.page === undefined) ? 'Auth' : _data.page);
  const update = (e) => {
      setPage(e);
  }
  _listen.page = update;
  return [page];
}
Store.setPage = (o) => {
    _data.page = o;
    localStorage.setItem('page',enCode(_data));
    _listen.page(o);
}

Store.useModule = () => {
  const [page,setPage] = React.useState((_data.module === undefined) ? 'Main' : _data.module);
  const update = (e) => {
      setPage(e);
  }
  _listen.module = update;
  return [page];
}
Store.setModule = (o) => {
    _data.module = o;
    localStorage.setItem('page',enCode(_data));
    _listen.module(o);
}
Store.getModule = (o) => {
   return _data.module;
}

Store.setSort = (p,o) => {
    if(_data.sort === undefined) _data.sort = {};
    _data.sort[p] = o;
    localStorage.setItem('page',enCode(_data));
    return o;
}
Store.getSort = (p) => {
   if(_data.sort === undefined) return true;
   if(_data.sort[p] === undefined) return true;
   return _data.sort[p];
}

Store.setView = (p,o) => {
    if(_data.view === undefined) _data.view = {};
    _data.view[p] = o;
    localStorage.setItem('page',enCode(_data));
    return o;
}
Store.getView = (p) => {
   if(_data.view === undefined) return false;
   if(_data.view[p] === undefined) return false;
   return _data.view[p];
}

Store.useListener = (o,f) => {
   return _listen[o] = f;
}

Store.setListener = (o,j) => {
   return _listen[o](j);
}

export default Store;
