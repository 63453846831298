'use strict'
import React from 'react'
import Input from './Form/Input';
import Password from './Form/Password';
import Button from './Form/Button';
import ButtonIcon from './Form/ButtonIcon';
import Link from './Form/Link';
import Links from './Form/Links';
import Search from './Form/Search';
import Text from './Form/Text';
import Toogle from './Form/Toogle';
import Select from './Form/Select';
import Mark from './Form/Mark';
import Brands from './Form/Brands';
import Price from './Form/Price';
import Categories from './Form/Categories';
import Markdown from './Form/Markdown';
import Time from './Form/Time';
import Files from './Form/Files';

import Date from './Form/Date';

let Form = {};

let listener = {};

const Init = () => {
   let [list,setList] = React.useState([]);
   listener.set = (get) => setList([...list,get]);
   listener.del = (key) => setList([]);
   return (<>{list.map(i => i)}</>)
}

const open = (e,callback) => {
   listener.set(e);
}

Form.Init = Init;
Form.open = open;
Form.Input = Input;
Form.Date = Date;
Form.Password = Password;
Form.Button = Button;
Form.ButtonIcon = ButtonIcon;
Form.Time = Time;
Form.Link = Link;
Form.Links = Links;
Form.Search = Search;
Form.Text = Text;
Form.Mark = Mark;
Form.Toogle = Toogle;
Form.Select = Select;
Form.Brands = Brands;
Form.Price = Price;
Form.Files = Files;
Form.Categories = Categories;
Form.Markdown = Markdown;

export default Form;
