import React from 'react';

const Links = ({children}) => {

  return (
        <dl ui-field="links" >
            {children}
        </dl>
  );
}

export default Links;
