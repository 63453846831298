import React from 'react';

const Link = ({label,icon,onClick}) => {

  return (
        <span ui-link="" onClick={onClick} >{icon !== undefined ?  <i className={`icon icon-${icon}`} /> :  "" }     {label}</span>
  );
}

export default Link;
