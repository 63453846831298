import React from 'react';
import on from './.onEvent';

const Input = ({label,error,view,name,value,onValue,onSave,after,onBlur,item,placeholder,contextmenu,iSedit = true}) => {

  let [edit,setEdit] = React.useState((!iSedit) ? false : (contextmenu === undefined) ? true : false);
  const text = React.useRef(value);
  const elem = React.useRef(null);

  React.useEffect(() => {
      elem.current.textContent = value;
  },[value])

  React.useEffect(() => {
     setEdit(iSedit);
  },[iSedit])

  let handleChange = (e) => {
      text.current = e.target.textContent;
      if(onValue === undefined) return;
      onValue({value : e.target.textContent, name: name});
  };

  let BoxTitle = () => {  return (label) ? <dt>{label}</dt> : '';  }
  let BoxError = () => {  return (error) ? <em>{error}</em> : '';  }

  let contextMenu = (e) => {
      if(contextmenu !== true) return;
      e.preventDefault();
      e.target.setAttribute('contentEditable','true')
      e.target.focus();
      setEdit(!edit);
  }

  let _onBlur = (e) => {
      if(e.target.textContent.length === 0){ e.target.textContent = ""; }
      if(onBlur !== undefined) onBlur({value : e.target.textContent, name: name, item: item});
  }

  return (
        <div ui-field="string" >
        {BoxTitle()}
        <div className="--input"
             onInput={handleChange}
             onContextMenu={contextMenu}
             onPaste={on.PasteOne}
             onKeyDown={on.KeyDown}
             onBlur={_onBlur}
             onFocus={on.Focus}
             name={name}
             edit={''+edit}
             placeholder={placeholder}
             contentEditable={edit}
             suppressContentEditableWarning="true"
             ref={elem}
             after={after} >
             {text.current}
        </div>
        {(contextmenu && edit) ? <div className='form_backgraund' onClick={() => { onSave({ value: text.current, name: name, item: item  }); setEdit(false)} } ></div> : '' }
        {BoxError()}
      </div>
  );
}

export default Input;
