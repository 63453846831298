import React from 'react';

const ButtonIcon = ({thema,icon,onClick}) => {

  return (
        <div ui-component="button-icon" onClick={onClick} className={thema} >
           <i className={`icon icon-${icon}`} />
        </div>
  );
}

export default ButtonIcon;
