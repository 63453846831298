import React from 'react';
import on from './.onEvent';

const Text = ({label,error,view,name,value,onValue,after,onBlur,contextmenu,id,onSave,hide,placeholder}) => {

  let [edit,setEdit] = React.useState((contextmenu === undefined) ? true : false);

  let [show,setShow] = React.useState((hide === undefined) ? true : false);

  const text = React.useRef(value);

  let onShow = () => {
      if(hide !== true) return;
      setShow(!show);
  }

  let handleChange = (e) => {
      text.current = e.target.innerText;
      if(onValue === undefined) return;
      onValue({value : e.target.innerText, name: name});
  };

  let contextMenu = (e) => {
      if(contextmenu !== true) return;
      if(edit === true) return;
      e.preventDefault();
      e.target.setAttribute('contentEditable','true')
      e.target.focus();
      setEdit(!edit);
      if(hide !== true) return;
      setShow(true);
  }

  let paste = (e) => {
      e.preventDefault();
      navigator.clipboard.readText()
      .then(text => { text = text.replace(/^\s*/,'').replace(/\s*$/,''); window.document.execCommand('insertText', false, text); })
      .catch(err => {  });
  }

  let _onBlur = (e) => {
      if(e.target.textContent.length === 0) e.target.textContent = "";
      if(onBlur !== undefined) onBlur({value : e.target.textContent, name: name, id: id});
  }

  return (
        <dl ui-field="text" >
        {(label) ? <dt>{label}</dt> : ""}
        <dd className="--input"
             onInput={handleChange}
             edit={''+edit}
             data-show={show}
             onClick={onShow}
             placeholder={placeholder}
             onContextMenu={contextMenu}
             onPaste={paste}
             name={name}
             contentEditable={edit}
             onBlur={_onBlur}
             spellCheck={false}
             suppressContentEditableWarning="true"
             after={after} >{value}</dd>
        {(contextmenu && edit) ? <div className='form_backgraund' onClick={() => { onSave({ value: text.current, name: name, id: id }); setEdit(false)} } ></div> : '' }

        </dl>
  );
}

export default Text;
