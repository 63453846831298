import React from 'react'
import Store from '../Store'
import Api from '../Api'

import ChequeDateTime from './Formatting/ChequeDateTime'

const _Date = (x,y) => {
  if(y === undefined){
     y = x;
     x = new Date();
  }else{
     x = new Date(x);
  }
  let replaces = {
        yyyy: x.getFullYear(),
          yy: (''+x.getFullYear()).slice(-2),
          mm: ('0'+(x.getMonth()+1)).slice(-2),
          dd: ('0'+x.getDate()).slice(-2),
          HH: ('0'+x.getHours()).slice(-2),
          MM: ('0'+x.getMinutes()).slice(-2),
          SS: ('0'+x.getSeconds()).slice(-2),
          __: `  \u00A0\u00A0\u00A0\u00A0  `,
          _: `  \u00A0\u00A0  `
    };
    for(const replace in replaces){
        y = y.replace(replace,replaces[replace]);
    }
    return y;
}
const _Number = (a,b) => {
    let c = b + '';while(c.length < a)c = '0' + c;return c;
}
const _Money = (n) => {
    return Math.round(n).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1 ").slice(0, -3);

    //parseFloat(n).toFixed(0).replace(/(\d)(?=(\d{3})+\.)/g, "$1 ");
}
const _Month = (n) => {
    let obj = {
          1: { val: '01', name: 'Январь',   names: 'Января',   abc: 'Янв',  },
          2: { val: '02', name: 'Февраль',  names: 'Февраля',  abc: 'Февр', },
          3: { val: '03', name: 'Март',     names: 'Марта',    abc: 'Март', },
          4: { val: '04', name: 'Апрель',   names: 'Апреля',   abc: 'Апр',  },
          5: { val: '05', name: 'Май',      names: 'Мая',      abc: 'Май',  },
          6: { val: '06', name: 'Июнь',     names: 'Июня',     abc: 'Июнь', },
          7: { val: '07', name: 'Июль',     names: 'Июля',     abc: 'Июль', },
          8: { val: '08', name: 'Август',   names: 'Августа',  abc: 'Авг',  },
          9: { val: '09', name: 'Сентябрь', names: 'Сентебря', abc: 'Сент', },
          10:{ val: '10', name: 'Октябрь',  names: 'Октября',  abc: 'Окт',  },
          11:{ val: '11', name: 'Ноябрь',   names: 'Ноября',   abc: 'Нояб', },
          12:{ val: '12', name: 'Декабрь',  names: 'Декабря',  abc: 'Дек',  },
          }
   return obj[n];
}


const _Translit = (word) => {
	var answer = '';
	var converter = {
		'а': 'a',    'б': 'b',    'в': 'v',    'г': 'g',    'д': 'd',
		'е': 'e',    'ё': 'e',    'ж': 'zh',   'з': 'z',    'и': 'i',
		'й': 'y',    'к': 'k',    'л': 'l',    'м': 'm',    'н': 'n',
		'о': 'o',    'п': 'p',    'р': 'r',    'с': 's',    'т': 't',
		'у': 'u',    'ф': 'f',    'х': 'h',    'ц': 'c',    'ч': 'ch',
		'ш': 'sh',   'щ': 'sch',  'ь': '',     'ы': 'y',    'ъ': '',
		'э': 'e',    'ю': 'yu',   'я': 'ya',

		'А': 'A',    'Б': 'B',    'В': 'V',    'Г': 'G',    'Д': 'D',
		'Е': 'E',    'Ё': 'E',    'Ж': 'Zh',   'З': 'Z',    'И': 'I',
		'Й': 'Y',    'К': 'K',    'Л': 'L',    'М': 'M',    'Н': 'N',
		'О': 'O',    'П': 'P',    'Р': 'R',    'С': 'S',    'Т': 'T',
		'У': 'U',    'Ф': 'F',    'Х': 'H',    'Ц': 'C',    'Ч': 'Ch',
		'Ш': 'Sh',   'Щ': 'Sch',  'Ь': '',     'Ы': 'Y',    'Ъ': '',
		'Э': 'E',    'Ю': 'Yu',   'Я': 'Ya'
	};

	for (var i = 0; i < word.length; ++i ) {
		if (converter[word[i]] == undefined){
		  	answer += word[i];
		} else {
			  answer += converter[word[i]];
		}
	}

	return answer;
}



const _VkLink = (j) => {
   if(j !== ''){
      return (<em  onClick={ () => window.open(j, "_blank") } >vk</em>);
   }else{
      return '';
   }
}
const _isNaN = (value) => {
    return /^-?[\d.]+(?:e-?\d+)?$/.test(value);
}
const _Quot = (value) => {
    return value.replace(/&quot;/gi, '"');
}
const _ArraySort = (array,key) => {
    return array.sort((prev, next) => next[key] - prev[key]);
}
const _ArraySortDesc = (array,key) => {
    return array.sort((prev, next) => prev[key] - next[key]);
}
const _ArraySortTime = (array,key) => {
    return array.sort((prev, next) => new Date(prev[key]).getTime() - new Date(next[key]).getTime());
}
const _ArraySumma = (array,key) => {
  return array.reduce((a, b) => a + (b[key] || 0), 0);
  }
const _ArrayGroup = function groupByArray(xs, key){
     return xs.reduce(function(rv, x){
       let v = key instanceof Function ? key(x) : x[key];
       if ((rv[v] !== undefined)){
            rv[v][x.id] = x;
       }else {
            rv[v] = {};
            rv[v][x.id] = x;
       }
       return rv;
  }, {});
}
const _ArrayMove = (arr, old_index, new_index) => {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);

  /*  function arrayMove(array, from, to) {
        array.splice(to, 0, array.splice(from, 1)[0]);
    }*/



    return arr;
    // returns [2, 1, 3] console.log(array_move([1, 2, 3], 0, 1));
}
const _SortList = (list,items,tabs,update) => {
   if(list[tabs] !== undefined && list[tabs].list !== ''){
      let _user = Object.keys(items[tabs]);
      let _list = list[tabs].list.split(",") // получаем список
      let valid = _list.filter(e => ~_user.indexOf(e));  // удаляем удаленных
      let all = valid.concat(_user); // объединяем списки
      let sort = all.filter((item, pos) => {return all.indexOf(item) === pos}); // удаляем списки
      if(update !== undefined && JSON.stringify(_list) !== JSON.stringify(sort)){
          update(sort.join())
      }
      return sort;
   }else{
      update(Object.keys(items[tabs]).join())
      return Object.keys(items[tabs]);
   }
}
const _SortListObject = (list,items,update) => {
   if(list !== ''){
      let _user = Object.keys(items);
      let _list = list.split(",") // получаем список
      let valid = _list.filter(e => ~_user.indexOf(e));  // удаляем удаленных
      let all = valid.concat(_user); // объединяем списки
      let sort = all.filter((item, pos) => {return all.indexOf(item) === pos}); // удаляем списки
      if(update !== undefined && JSON.stringify(_list) !== JSON.stringify(sort)){
         update(sort.join())
      }
      return sort;
   }else{
      if(update !== undefined) update(Object.keys(items).join())
      return Object.keys(items);
   }
}

const _DateSort = (s) => {
    let month = ['Января', 'Февраля', 'Марта', 'Апреля', 'Мая', 'Июня', 'Июля', 'Августа', 'Сентября', 'Октября', 'Ноября', 'Декабря'];
    let date = new Date(s);
    return {day: (''+date.getFullYear()).slice(-2)+''+('0'+(date.getMonth()+1)).slice(-2)+''+('0'+date.getDate()).slice(-2), name: date.getDate()+' '+month[date.getMonth()]};
}

const _CountTags = (value) => {
    return  (value !== undefined && value !== "") ? value.split(",").length : 0 ;
}

/*
const _SortList = (list,items,tabs,update) => {
   if(list[tabs] !== undefined && list[tabs].list !== ''){
      let _user = Object.keys(items[tabs]);
      let _list = list[tabs].list.split(",") // получаем список
      let valid = _list.filter(e => ~_user.indexOf(e));  // удаляем удаленных
      let all = valid.concat(_user); // объединяем списки
      let sort = all.filter((item, pos) => {return all.indexOf(item) === pos}); // удаляем списки
      if(update !== undefined && JSON.stringify(_list) !== JSON.stringify(sort)){
          update(sort.join())
      }
      return sort;
   }else{
      update(Object.keys(items[tabs]).join())
      return Object.keys(items[tabs]);
   }
}*/

const _ContextMenu = (a,send,e) => {
   e.preventDefault();
   let div = document.createElement("div");
   div.classList.add("ui-context-menu");
   div.style.top = e.pageY+'px';
   div.style.left = e.pageX+'px';

   a.forEach(i => {
      let label = document.createElement("label");
      label.textContent = i.label;
      label.addEventListener('click',() => {
         send(i.action);
         div.remove()
         document.removeEventListener('mousedown', onMouseDown);
      }, false);
      div.appendChild(label);
   });

   document.body.appendChild(div);

  function onMouseDown(e){
    e.preventDefault();
    if(e.path.indexOf(div) === -1){
       div.remove()
       document.removeEventListener('mousedown', onMouseDown);
    }
  }
  document.addEventListener('mousedown', onMouseDown, false);
}

//<>{state.data[state.tabs].map((i,key) => <Item key={key} data={i} />) }</>

const _Image = (type,id,a) => {
  let _type = {
      1: { length: 3},
      2: { length: 3},
      3: { length: 3},
      4: { length: 11},
      5: { length: 3},
      6: { length: 3},
      7: { length: 3},
   }
   if(a === 'name'){
      return 'x'+_Number(2,type)+'_'+_Number(_type[type].length,id)+'.png';
   }else{
      return 'https://api.yamogu.online/image/x'+_Number(2,type)+'_'+_Number(_type[type].length,id)+'.png';
   }

}

const _TypeName = (type) => {
  let _type = {
      2: "Книга",
      3: "Фильм",
      4: "Стаья",
      7: "Кейс",
   }
   return _type[type];
}

const _Video = (type,id) => {
  let _type = {
      1: { length: 3},
      2: { length: 3},
      3: { length: 3},
      4: { length: 11},
      5: { length: 3},
      6: { length: 3},
      7: { length: 3},
   }
   return 'https://api.yamogu.online/video/x'+_Number(2,type)+'_'+_Number(_type[type].length,id)+'.mp4';
}





const _Online = (d) => {
  if(d === 0) return '-';
  let datatime = new Date().getTime();
  function getNumberOfDays(start, end) {
    const date1 = new Date(start);
    const date2 = new Date(end);
    const oneDay = 1000 * 60 * 60;
    const diffInTime = date2.getTime() - date1.getTime();
    const diffInDays = Math.round(diffInTime / oneDay);

    switch (true) {
      case diffInDays < 24: return diffInDays +"ч";
      case diffInDays < 288: return (Number.parseInt(diffInDays / 288) + 1) +"д";
      case diffInDays < 8640: return (Number.parseInt(diffInDays / 8640) + 1) +"м";
      default: return "-";
    }

  }
  return getNumberOfDays(d,  datatime);
}

const _Online24 = (d) => {
  if(d === 0) return '-';
  let datatime = new Date().getTime();
  function getNumberOfDays(start, end) {
    const date1 = new Date(start);
    const date2 = new Date(end);
    const oneDay = 1000 * 60 * 60;
    const diffInTime = date2.getTime() - date1.getTime();
    const diffInDays = Math.round(diffInTime / oneDay);

    switch (true) {
      case diffInDays < 24: return diffInDays +"ч";
      case diffInDays < 288: return "24ч+";
      default: return "-";
    }

  }
  return getNumberOfDays(d,  datatime);
}


const _Label = (list) => {
  if(list !== undefined && list !== ""){
     list = list.split(",");
  }else{
      list = []
  }
  return (<>{list.map((i,key) =>  <span data-label={Store.Label(i).color} className="kids-label" key={key}>{Store.Label(i).name}</span>)}</>);
}

const _SplitPages = (data,text,base) => {
     let a = [];
     let n = 1;
     let x = 0;
     let l = {};
     let m = 50;
     l[n] = [];

     /*data.forEach((i) => {
        if(i.text.toLowerCase().indexOf(text) > -1){
            a.push(i.id);
        }
     });*/

     data.forEach(i => {
        x++;
        if(x <= m){
          l[n].push(i);
        }else{
          n++;
          x = 1;
          l[n] = [];
          l[n].push(i);
        }
     });
     return l;
}

const _UUIDv4 = () => {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}

const _ImageParse = (url) => {
  if(!url.indexOf('https')){
     return url;
  }else{
     if(url === "") return 'https://'+Store.getSITE()+"/image/no_photo.webp";
     return 'https://'+ Store.getSITE() + "/image/" +url;
  }
}

const declensionBblok = (n) => {
  if(n === 0) return "блоков";
  if(n === 1) return "блок";
  if(n < 5) return "блокa";
  if(n > 4 && n < 20) return "блоков";
  if(n > 20){
    let s = " "+n;
    return declensionBblok(Number(s.slice(-1)));
  }
}

const declensionPack = (n) => {
  if(n === 0) return "пачек";
  if(n === 1) return "пачка";
  if(n < 5) return "пачки";
  if(n > 4 && n < 20) return "пачек";
  if(n > 20){
    let s = " "+n;
    return declensionBblok(Number(s.slice(-1)));
  }
}

const _InfoPrice = (a,data) => {
  let t = { position: '', summa: 0};
  if(a > 9){
     let b = a / 10;
     let s = b+'';
     let d = s.split('.');
     let f = parseInt(d[0]);
     let p = parseInt(d[1]);
     let x = '';
     let h = 0;

     if(!isNaN(p)){
        x = `и ${p} ${declensionPack(p)} `
        h = p * data.price;
     }

     if(f > 4){
        t.position = `${f} ${declensionBblok(f)} ${x}`
        t.summa = f * data.price_pallet + h;
     }

     if(f > 49){
        t.position = `${f} ${declensionBblok(f)} ${x}`
        t.summa = f * data.price_hand + h;
     }

     if(f < 5){
        t.position = `${f} ${declensionBblok(f)} ${x}`
        t.summa = f * data.price_hand + h;
     }

  }else{
     t.position = `${a} ${declensionPack(a)}`
     t.summa = a * data.price;
  }
  return t;
}

let Formatting = {
  'Date': _Date,
  'Number': _Number,
  'ImageParse': _ImageParse,
  'UUIDv4': _UUIDv4,
  'CountTags': _CountTags,
  'TypeName': _TypeName,
  'Money': _Money,
  'Month': _Month,
  'DateSort': _DateSort,
  'ArraySort': _ArraySort,
  'ArraySortDesc': _ArraySortDesc,
  'ArraySumma': _ArraySumma,
  'ArraySortTime': _ArraySortTime,
  'ArrayGroup': _ArrayGroup,
  'ArrayMove': _ArrayMove,
   'isNAN': _isNaN,
   'InfoPrice': _InfoPrice,
   'ChequeDateTime': ChequeDateTime,
   'Quot': _Quot,
   'Online': _Online,
   'Online24': _Online24,
   'VkLink': _VkLink,
   'SortList': _SortList,
   'Image': _Image,
   'Video': _Video,
   'Translit': _Translit,
   'ContextMenu': _ContextMenu,
   'SplitPages': _SplitPages,
   'SortListObject': _SortListObject,
   'Label': _Label,
}

export default Formatting;
