import React from 'react';

const Toogle = ({name,id,color,value,onValue}) => {

    const [data, setData] = React.useState(value);

    let link = (e) => {
        let set = (parseInt(data) === 0) ? 1 : 0;
        setData(set);
        if(onValue !== undefined) onValue({'value': set, 'name': name, 'id': id});
    }

    React.useEffect(() => {
         setData(value);
    },[value]);

    return (
        <div ui-toogle={color || "blue"} onClick={link} data={data}></div>
    );
}

export default Toogle;
