import React from 'react';
import on from './.onEvent';

const Files = ({label,name,value,onValue,item,placeholder}) => {

  const [text,setText]= React.useState(value);
  const elem = React.useRef(null);

  React.useEffect(() => {

  },[])

  const onChange = (e) => {

    let n = e.target.files[0].name;

    console.log(n.split('.').pop())

    //str = str.substring(0, str.length - 1);


  }

  let BoxTitle = () => {  return (label) ? <dt>{label}</dt> : '';  }

  return (
        <div ui-field="string" >
          {BoxTitle()}
          <input type="file" onChange={onChange} id={name} />
          <div  className="--files" >
             <label htmlFor={name}>{text}</label>
          </div>
       </div>
  );
}

export default Files;
