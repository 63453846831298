import React from 'react';
import Formatting from '../Formatting';
import Form from '../Form';

const Time = (props) => {

  let title = () => {  return (props.title) ? <dt>{props.title}</dt> : '';  }
  let error = () => {  return (props.error) ? <em>{props.error}</em> : '';  }
  let edit = (props.edit !== undefined) ? props.edit : true;
  let active = (props.active !== undefined) ? props.active : true;
  let placeholder = (props.placeholder !== undefined) ? props.placeholder : '';
  let date = Formatting.Date((props.value === undefined) ? new Date() : props.value,'HH:MM');
  let elem = React.useRef(null);

  if(edit){   }

  if(active){   }

  let onSend= (d) => {
      props.onValue({value : d, name: props.name});
   }

   let onValue = (d) => {
      elem.current.textContent = d;

   }

  let open = (e) => {
      const element = e.target.getBoundingClientRect();
      const x = element.left;
      const y = element.top;
      Form.open(<BoxTime key={new Date().getTime()} position={{x,y}} onValue={onValue} date={date} closed={onSend} />);
  }
  return (< >{(props.view === 'icon') ? <i data-icon="F043" hover="ff9800" onClick={open} ></i> :
  <dl type='time' view={props.view}>
  {title()}
  <dd onClick={open}
      ref={elem}
      placeholder={placeholder}
      after={props.after} >
      {date}
  </dd>
  {error()}
  </dl>
   }</>)
}

export default Time;

const BoxTime = (props) => {

  let load = props.date.split(':');
  let data = {h:Formatting.Number(2,load[0]),m:Formatting.Number(2,load[1])};
  let choice = 'hour';
  let [position,setPosition] = React.useState({});
  let move = false;
  let scroll = 0;
  let interval = 0;

  let elem = {
    body:React.useRef(null),
    time:React.useRef(null),
    hour:React.useRef(null),
    timeText:React.useRef(null),
    hourText:React.useRef(null),
  }

  React.useEffect(() => {
      setPosition(elem.body.current.getBoundingClientRect())
      elem.hour.current.style.transform = 'rotate('+(data.h*15)+'deg)';
      elem.time.current.style.transform = 'rotate('+(data.m*6)+'deg)';
  },[elem.body,elem.hour,elem.time,data.m,data.h]);

  let omd = () => {  move = true;  }
  let omu = () => {  move = false;
      choice = (choice === 'hour') ? 'time' : 'hour';
      elem.body.current.setAttribute('active',choice)
  }
  let omm = (e) => {
      if(move){
         let r = Math.atan2((e.pageX - ((position.x) + (78))), (e.pageY - ((position.y) + (78))));
         let d = (r * (180 / Math.PI) * -1) + 180;
         elem[choice].current.style.transform = 'rotate('+d+'deg)';
         if(choice === 'hour'){
            data = {...data,h:Formatting.Number(2,parseInt(d/15, 10))}
            elem.hourText.current.textContent = data.h;
         }else{
            data = {...data,m:Formatting.Number(2,parseInt(d/6, 10))}
            elem.timeText.current.textContent = data.m;
         }
         props.onValue(data.h+':'+data.m);
     }
  }
  let omw = (e) => {
    if(choice === 'hour'){
       if(e.deltaY > 0){
           scroll += e.deltaY;
           let i = parseInt(scroll / 50);
           if(interval !== i){
              interval = i;
              let a = parseInt(data.h, 10) + 1;
              a = (a > 23) ? 0 : a
              data = {...data,h:Formatting.Number(2,a)}
              elem.hourText.current.textContent = data.h;
              elem[choice].current.style.transform = 'rotate('+(data.h*15)+'deg)';
          }
       }else{
          scroll -= e.deltaY;
          let i = parseInt(scroll / 50);
          if(interval !== i){
            interval = i;
            let a = parseInt(data.h, 10) - 1;
            a = (a < 0) ? 23 : a
            data = {...data,h:Formatting.Number(2,a)}
            elem.hourText.current.textContent = data.h;
            elem[choice].current.style.transform = 'rotate('+(data.h*15)+'deg)';
         }
      }
   }else{
      if(e.deltaY > 0){
          scroll += e.deltaY;
          let i = parseInt(scroll / 50);
          if(interval !== i){
             interval = i;
             let a = parseInt(data.m, 10) + 1;
             a = (a > 59) ? 0 : a
             data = {...data,m:Formatting.Number(2,a)}
             elem.timeText.current.textContent = data.m;
             elem[choice].current.style.transform = 'rotate('+(data.m*6)+'deg)';
         }
      }else{
         scroll -= e.deltaY;
         let i = parseInt(scroll / 50);
         if(interval !== i){
           interval = i;
           let a = parseInt(data.m, 10) - 1;
           a = (a < 0) ? 59 : a
           data = {...data,m:Formatting.Number(2,a)}
           elem.timeText.current.textContent = data.m;
           elem[choice].current.style.transform = 'rotate('+(data.m*6)+'deg)';
        }
     }
    }
    props.onValue(data.h+':'+data.m);
  }
  let [active,setActive] = React.useState(true);
  let exit = () => { setActive(false); props.closed(data.h+':'+data.m); }

  let y = ((window.innerHeight - props.position.y + 33) < 320) ? props.position.y - 312 : props.position.y + 33;
  let x = ((window.innerWidth - props.position.x) < 265) ? props.position.x - 235 : props.position.x;

  return (<>
      {(active) ?  <><select-o key={new Date().getTime()} onClick={() => exit()} ></select-o>
                 <select-c onMouseDown={omd}
                           onMouseUp={omu}
                           onMouseMove={omm}
                           onWheel={omw}
                           active={choice} ref={elem.body} style={{top: y + 'px', left: x+"px" }}  >
                  <dial-d></dial-d>
                  <dial-s><tt text='hour' ref={elem.hourText} >{data.h}</tt> : <tt text='time' ref={elem.timeText} >{data.m}</tt></dial-s>
                  <dial-a ref={elem.hour} ></dial-a>
                  <dial-b ref={elem.time} ></dial-b>
                </select-c></>
                 : '' }</>)

}
